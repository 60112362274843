import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Sectiontitle from '../components/Sectiontitle'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import PortfoliosView from '../components/PortfoliosView'

function Portfolios() {
  const [portfolios, setPortfolios] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [portfoliosPerPage] = useState(9)

  const [workprojects, setWorkprojects] = useState([])
  const [workProjectsPerPage] = useState(9)

  useEffect(() => {
    let mounted = true
    axios.get('/api/portfolios').then((response) => {
      if (mounted) {
        setPortfolios(response.data)
      }
    })
    return () => (mounted = false)
  }, [portfolios])

  const indexOfLastPortfolios = currentPage * portfoliosPerPage
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage
  const currentPortfolios = portfolios.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios,
  )

  useEffect(() => {
    let mounted = true
    axios.get('/api/workprojects').then((response) => {
      if (mounted) {
        setWorkprojects(response.data)
      }
    })
    return () => (mounted = false)
  }, [workprojects])

  const indexOfLastWorkprojects = currentPage * workProjectsPerPage
  const indexOfFirstWorkprojects = indexOfLastWorkprojects - workProjectsPerPage
  const currentWorkprojects = workprojects.slice(
    indexOfFirstWorkprojects,
    indexOfLastWorkprojects,
  )

  const paginate = (e, pageNumber) => {
    e.preventDefault()
    setCurrentPage(pageNumber)
  }

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Portfolios" />
          {<PortfoliosView portfolios={currentPortfolios} />}
          {!(portfolios.length > portfoliosPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={portfoliosPerPage}
              totalItems={portfolios.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>

        <div className="container mt-50">
          <Sectiontitle title="Work Projects" />
          {<PortfoliosView portfolios={currentWorkprojects} />}
          {!(workprojects.length > workProjectsPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={workProjectsPerPage}
              totalItems={workprojects.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Portfolios
