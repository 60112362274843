import Mock from '../mock'
// import { withNamespaces } from 'react-i18next';

const database = {
  information: {
    name: 'Manik',
    aboutContent:
      "I am a 'still in training' Developer, with a natural passion and curiosity for the tech world. Feel free to browse around! :)",
    // age: 'Guess it, less than 30 anyway :)',
    // phone: '',
    language: 'Italian, English, Spanish, French, Indonesian',
    email: 'manik.aangurah@gmail.com',
    // address: '121 King Street, Melbourne, Australia',
    // freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/Manik.Angurah/',
      instagram: 'https://www.instagram.com/keysnam/',
      linkedin: 'https://www.linkedin.com/in/manik-a-a-ngurah-59b228149/',
      codepen: 'https://codepen.io/manik5',
      github: 'https://github.com/Manik5',
    },
    brandImage: '/images/profile_cropped.JPG',
    aboutImage: '/images/profile_picture.jpg',
    aboutImageLg: '/images/profile_picture.jpg',
    cvfile: '/files/empty.pdf',
  },
  services: [
    {
      title: 'Web Design & Mockups',
      icon: 'color-pallet',
      details:
        'Before diving into the code, I like to design the project first, without any logic, with tools like Figma and Webflow.',
    },
    {
      title: 'Web Development',
      icon: 'code',
      details:
        "I'm currently using Ruby On Rails and React to develop Full Web Application, check out some of my projects in the portfolio section.",
    },
    {
      title: 'Landing Page / Static Website',
      icon: 'laptop',
      details:
        'I can provide a beautiful and responsive landing page or static website to boost your business.',
    },
  ],
  // reviews: [
  //   {
  //     id: 1,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
  //     author: {
  //       name: 'Burdette Turner',
  //       designation: 'Web Developer, Abc Company'
  //     }
  //   },
  //   {
  //     id: 2,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
  //     author: {
  //       name: 'Susan Yost',
  //       designation: 'Client'
  //     }
  //   },
  //   {
  //     id: 3,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
  //     author: {
  //       name: 'Irving Feeney',
  //       designation: 'Fiverr Client'
  //     }
  //   }
  // ],
  skills: [
    {
      title: 'HTML5',
      value: 80,
    },
    {
      title: 'CSS3',
      value: 70,
    },
    {
      title: 'Javascript',
      value: 70,
    },
    {
      title: 'Ruby',
      value: 90,
    },
    {
      title: 'Rails',
      value: 90,
    },
    {
      title: 'ReactJS',
      value: 70,
    },
    {
      title: 'Git',
      value: 80,
    },
    {
      title: 'Unix System',
      value: 90,
    },
    {
      title: 'Bash / Shell',
      value: 90,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: 'Friend It',
      subtitle: 'Your community first rental solution',
      imageUrl: '/images/friend_it.png',
      largeImageUrl: ['/images/friend_it.png'],
      url: 'http://friend-it.herokuapp.com/',
    },
    {
      id: 2,
      title: 'Mr. Cocktail',
      subtitle: 'Simple cocktail manager. Feel free to add your own!',
      imageUrl: '/images/cocktails.png',
      largeImageUrl: ['/images/cocktails.png', '/images/cocktails.png'],
      url: 'https://mister-cocktail-manik.herokuapp.com/cocktails/',
    },
    {
      id: 3,
      title: 'Crown Music',
      subtitle: 'Music shop created with React/Redux',
      imageUrl: '/images/crown_music.png',
      largeImageUrl: ['/images/crown_music.png'],
      url: 'https://crown-music-live.herokuapp.com/',
    },
    {
      id: 4,
      title: 'Covid 19 Tracker',
      subtitle: 'Check out the global situation about Covid 19',
      imageUrl: '/images/covid_19.png',
      largeImageUrl: ['/images/covid_19.png'],
      url: 'https://covid-19-tracker-delta.now.sh/',
    },
    {
      id: 5,
      title: 'Photograpy Portfolio',
      subtitle: 'A photography portfolio commissioned by a friend',
      imageUrl: '/images/rossi_marcello.png',
      largeImageUrl: ['/images/rossi_marcello.png'],
      url: 'https://www.rossimarcello.com/',
    },
    {
      id: 6,
      title: 'Demo Platformer Game',
      subtitle: '2D Platform game created with Godot Engine',
      imageUrl: '/images/platformer.jpg',
      largeImageUrl: ['/images/platformer.jpg'],
      url: 'https://keysnam.itch.io/godot-platform-demo-test',
    },
    {
      id: 7,
      title: 'Dodge the Creeps Demo',
      subtitle: '2D game created with Godot Engine',
      imageUrl: '/images/dodgethecreeps.jpg',
      largeImageUrl: ['/images/dodgethecreeps.jpg'],
      url: 'https://keysnam.itch.io/dodgethecreepsdemo',
    },
  ],
  workprojects: [
    {
      id: 1,
      title: 'Cardtrader',
      subtitle: 'A marketplace for collectible card games',
      imageUrl: '/images/cardtrader.jpg',
      largeImageUrl: ['/images/cardtrader.jpg'],
      url: 'https://www.cardtrader.com/',
    },
    {
      id: 1,
      title: 'ManaTraders',
      subtitle: 'Rent Magic The Gathering Online cards with a convenient monthly subscription',
      imageUrl: '/images/manatraders.jpg',
      largeImageUrl: ['/images/manatraders.jpg'],
      url: 'https://www.manatraders.com/',
    },
    {
      id: 1,
      title: 'Pacabots',
      subtitle: 'An investing bot for the US stock markets',
      imageUrl: '/images/pacabots.jpg',
      largeImageUrl: ['/images/pacabots.jpg'],
      url: 'https://www.pacabots.com/',
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: '2020 - Present',
        position: 'Ruby On Rails Developer',
        company: 'CardTrader',
        details:
          'Develop robust scalable and secure features on the 3 main projects of Cardtrader: Cardtrader.com, Manatraders.com, Pacabots.com. Write clean maintainable and efficient code. Participate at all stages of the development lifecycle. Implement best practices of test-driven development continuous integration refactoring and code standards.',
      },
      {
        id: 2,
        year: '2018 - 2020',
        position: 'Night Supervisor',
        company: 'Talassio Collection',
        details:
          "Assisting with the overall operation of the hotel during the night time hours. Attending to guests' needs and requirements including check in and check out procedures. Handle telephone enquiries including hotel reservations. Check all the night audit reports and complete the end of day accurately and in a timely manner.",
      },
      {
        id: 3,
        year: '2014 - 2017',
        position: 'Front / Back Office Agent',
        company: 'Idea Hotel Plus Savona',
        details:
          "Greeting and thanking guests in a sincere, friendly manner. Checking guests in on arrival and out on departure. Posting charges to appropriate guest accounts. Anticipating and addressing guests' needs, and resolving their problems and complaints.",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: '2020',
        graduation: 'Full-Stack Web Development',
        university: 'Le Wagon',
        details:
          '9 full immersive week, learning the foundation of programming and some notion of computer science. I learned the basic of Back-End, using Ruby, MySql and PostgreSql, to the Front-End, with HTML, CSS and Javascript.',
      },
      {
        id: 2,
        year: '2009 - 2014',
        graduation: 'Hospitality and Tourism Diploma',
        university: 'Ipssar A. Migliorini',
        details:
          'Professional school, where I specialized in Hospitality, precisely in the concierge field. ',
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: 'Programmare è difficile? Molto.',
      featuredImage: '/images/code.jpeg',
      filesource: '../../blog/programmare-è-difficile-molto.md',
      createDay: '6',
      createMonth: 'February',
      createYear: '2020',
    },
    // {
    //   id: 2,
    //   title: 'Essere felici? Una vostra scelta quotidiana ',
    //   featuredImage: '/images/blog-image-5.jpg',
    //   filesource: '../../blog/essere-felici.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 6,
    //   title: 'How to choose javascript framework for project.',
    //   featuredImage: '/images/blog-image-6.jpg',
    //   filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 7,
    //   title: 'Web automation with python language.',
    //   featuredImage: '/images/blog-image-7.jpg',
    //   filesource: '../../blog/web-automation-with-python-language.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 8,
    //   title: 'Time to use latest technology for creating a website.',
    //   featuredImage: '/images/blog-image-8.jpg',
    //   filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 9,
    //   title: 'Think out of the box.',
    //   featuredImage: '/images/blog-image-9.jpg',
    //   filesource: '../../blog/think-out-of-the-box.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 10,
    //   title: 'Trending designs in 2020.',
    //   featuredImage: '/images/blog-image-1.jpg',
    //   filesource: '../../blog/trending-designs-in-2020.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 11,
    //   title: 'How to get 10k instagram followers?',
    //   featuredImage: '/images/blog-image-2.jpg',
    //   filesource: '../../blog/how-to-get-10k-instagram-followers.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 12,
    //   title: 'What NodeJS can do?',
    //   featuredImage: '/images/blog-image-3.jpg',
    //   filesource: '../../blog/what-nodejs-can-do.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 13,
    //   title: 'Futures of javascript.',
    //   featuredImage: '/images/blog-image-4.jpg',
    //   filesource: '../../blog/future-of-javascript.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 14,
    //   title: 'Popular javascript library in 2020.',
    //   featuredImage: '/images/blog-image-5.jpg',
    //   filesource: '../../blog/popular-javascript-library-in-2020.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // },
    // {
    //   id: 15,
    //   title: 'Promrammers must read books.',
    //   featuredImage: '/images/blog-image-6.jpg',
    //   filesource: '../../blog/programmers-must-read-books.md',
    //   createDay: "20",
    //   createMonth: 'February',
    //   createYear: "2020"
    // }
  ],
  contactInfo: {
    phoneNumbers: ['+39 3478062065'],
    emailAddress: ['manik.aangurah@gmail.com'],
    address: 'Based in Italy',
  },
}

Mock.onGet('/api/information').reply((config) => {
  const response = database.information
  return [200, response]
})

Mock.onGet('/api/services').reply((config) => {
  const response = database.services
  return [200, response]
})

Mock.onGet('/api/reviews').reply((config) => {
  const response = database.reviews
  return [200, response]
})

Mock.onGet('/api/skills').reply((config) => {
  const response = database.skills
  return [200, response]
})

Mock.onGet('/api/portfolios').reply((config) => {
  const response = database.portfolios
  return [200, response]
})

Mock.onGet('api/workprojects').reply((config) => {
  const response = database.workprojects
  return [200, response]
})

Mock.onGet('/api/experience').reply((config) => {
  const response = database.experience
  return [200, response]
})

Mock.onGet('/api/blog').reply((config) => {
  const response = database.blogs
  return [200, response]
})

Mock.onGet('/api/contactinfo').reply((config) => {
  const response = database.contactInfo
  return [200, response]
})
